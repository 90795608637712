<template>

  <div class="ml-2">

    <b-row>
      <b-col cols="12">

        <b-row>
          <b-col cols="4">

            <b-form-group :label="$t('PAGES.RENEWS.CREATED_FROM')">
              <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
            </b-form-group>

          </b-col>
          <b-col cols="4">
            <b-form-group :label="$t('PAGES.RENEWS.CREATED_TO')">
              <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
            </b-form-group>
          </b-col>
        </b-row>


      </b-col>
    </b-row>

    <div class="d-flex align-items-end flex-column">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="$emit('create_member_clicked')"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('MEMBER.CREATE')}}</a
      >
    </div>


    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>


    <b-table
      id="member-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="memberTable"
      @filtered="onFiltered"
      @sort-changed="onSortChanged"
      :tbody-tr-class="rowClass"
    >

      <template #cell(status)="row">
        <v-chip
          style="font-size: 10px; color: red; border-color: red;"
          v-if="row.item.status === 'EXITED'"
          color="red"
          outlined
        >
          {{ $t('MEMBER.STATUSES.EXITED') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: green; border-color: green;"
          v-else-if="row.item.status === 'MEMBER'"
          color="green"
          outlined
        >
          {{ $t('MEMBER.STATUSES.MEMBER') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: red; border-color: red;"
          v-else-if="row.item.status === 'REJECTED'"
          color="red"
          outlined
        >
          {{ $t('MEMBER.STATUSES.REJECTED') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: orange; border-color: orange;"
          v-else-if="row.item.status === 'PENDING_EXIT'"
          color="orange"
          outlined
        >
          {{ $t('MEMBER.STATUSES.PENDING_EXIT') }}
        </v-chip>

        <v-chip
          style="font-size: 10px; color: blue; border-color: blue;"
          v-else-if="row.item.status === 'PENDING'"
          color="blue"
          outlined
        >
          {{ $t('MEMBER.STATUSES.PENDING') }}
        </v-chip>

      </template>

      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm"
            @click.prevent="delete_member_clicked(row.item.id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>

    </b-table>


  </div>
</template>



<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import isBetween from 'dayjs/plugin/isBetween';
import { is_mobile } from '@/core/services/utils';

dayjs.extend(isBetween);

export default {
  name: 'MembersTable',
  mixins: [ toasts ],
  components: {
    
  },
  emits: ['create_member_clicked', 'delete', 'member_selected', 'createInvoiceClicked', 'print_invoice_clicked', 'download_invoice_clicked', 'email_sendout_clicked', 'create_pdf_clicked', 'downloadAllInvoicesClicked', 'selectInvoiceClicked', 'delete_invoice_clicked', 'submit_update', 'filters_changed', 'downloadMultipleInvoicesClicked'],
  computed: {
    ...mapGetters(['currentPeriodId', 'periods', 'settings']),
    currentPeriod() {
      return this.periods.find(period => period.id === this.currentPeriodId);
    }
  },
  mounted() {

    this.fields = this.fields.filter(item => item.visible);

    if (!this.settings.is_faith) {
      this.fields = this.fields.filter(item => item.key !== 'status');
    }
  },
  watch: {
    'filters.created_from'() {
      this.$emit('filters_changed', { created_from: this.filters.created_from, created_to: this.filters.created_to });
      this.refresh();
    },
    'filters.created_to'() {
      this.$emit('filters_changed', { created_from: this.filters.created_from, created_to: this.filters.created_to });
      this.refresh();
    },
    currentPeriod() {

    }
  },
  methods: {

    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.reg) {
          return 'tr-orange';
        }

        if (item.deceased || item.deactivated) {
          return 'tr-red';
        }
      }
      return null;
    },

    delete_member_clicked(member_id) {
      this.$emit('delete', member_id);
    },

    select_member_clicked(member_id) {
      this.$emit('member_selected', member_id);
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    get_options() {

      const options = {};

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      options.statuses = ['MEMBER', 'PENDING_EXIT', 'EXITED'];

      return options;
    },


    refresh() {
      this.$refs['memberTable'].refresh();
    },

    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['memberTable'].refresh();
    ajax_pagination: function(ctx, callback) {


      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();

      axios
        .post(`/member/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },


    async get_page_count() {

      try {
        const options = this.get_options();


        const res = await axios.post(`/member/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options });

        if (res.status === 200) {
          this.total_rows = res.data.count;
        }
      }
      catch (err) {
        console.error('get_page_count error ', err);
      }

    },

    gotoMemberClicked(member_id) {
      this.$router.push(`/ml-member-editor/${member_id}`)
    },
    onRowSelected(items) {
      this.selected = items;
    },

    getStatus(status) {
      return {
        PAID: this.$t('INVOICE.PAID'),
        NOT_PAID: this.$t('INVOICE.NOT_PAID'),
        PARTIALLY_PAID: this.$t('INVOICE.PARTIALLY_PAID')
      }[status];
    },
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },
    createInvoiceClicked() {
      this.$emit('createInvoiceClicked');
    },
    print_invoice_clicked(member_id) {
      this.$emit('print_invoice_clicked', member_id);
    },
    download_invoice_clicked(member_id) {
      this.$emit('download_invoice_clicked', member_id);
    },
    downloadMultipleInvoicesClicked() {
      this.$emit('downloadMultipleInvoicesClicked', this.selected);
      this.$refs.memberTable.clearSelected();
    },
    email_sendout_clicked() {
      this.$emit('email_sendout_clicked', this.get_options());
    },
    create_pdf_clicked() {
      this.$emit('create_pdf_clicked', this.get_options());
    },
    downloadAllInvoicesClicked() {
      this.$emit('downloadAllInvoicesClicked', this.filteredItems);
    },
    selectInvoiceClicked(member_id) {
      this.$emit('selectInvoiceClicked', member_id);
    },
    delete_invoice_clicked(member_id) {
      this.$emit('delete_invoice_clicked', member_id);
    },
    submit_update(member_id) {
      this.$emit('submit_update', member_id, this.paymentDropdowns[member_id].code);
    },
    updatePaymentStatus(member_id, value) {
      let newStatus = JSON.parse(JSON.stringify(this.paymentDropdowns));

      newStatus[member_id] = value;

      this.paymentDropdowns = newStatus;
    }
  },
  data() {
    return {
      total_rows: 0,
      orderBy: { created_at: 'desc' },
      allSelected: false,
      can_member: false,
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],

      filters: {
        created_from: null,
        created_to: null,
      },
      filteredItems: [],
      selected: [],
      fields: [

        {
          visible: is_mobile() ? false : true,
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },

        {
          visible: true,
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'name',
          label: this.$t('MEMBER.NAME'),
          sortable: false,
          formatter: (_, __, item) => {
            return item.secret ? '*******' : item.firstname + ' ' + item.lastname;
          },
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'post',
          label: this.$t('MEMBER.POST'),
          formatter: (_, __, item) => {
            return item.secret ? '*******' : item.post;
          },
          sortable: false,
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'email',
          label: this.$t('MEMBER.EMAIL'),
          formatter: (_, __, item) => {
            return item.secret ? '*******' : item.email;
          },

          sortable: false,
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: is_mobile() ? false : true,
          key: 'phone',
          label: this.$t('MEMBER.PHONE'),
          formatter: (_, __, item) => {
            return item.secret ? '*******' : item.phone;
          },
          sortable: false,
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: is_mobile() ? false : true,
          key: 'status',
          label: this.$t('MEMBER.STATUS'),
          sortable: false,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: true,
          key: 'actions',
          label: '',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


.bottom-alignment {
  height: 100%; // containing div must fill the full height of the parent div
  align-items: flex-end;
  justify-content: flex-end;
  vertical-align: bottom;
  margin-top: 20px;
  margin-left: 4px;
}

:deep th div {
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep .tr-orange {
  background-color: #ffb85d !important;
}

:deep .tr-red {
  background-color: #ffc7c7 !important;
}

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 25%;
}

:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
}


:deep td {
  vertical-align: middle !important;
}

:deep .form-group {
  margin-bottom: 2px;
}


@media screen and (max-width: 768px) {
  #multiple-members-btn {
    margin-bottom: 2rem;
  }

  .date-time-picker {
    margin: 0;
  }

  .filter-box {
    margin-top: 1rem;
    padding: 0 !important;
  }
}
#status-filter {
  width: 200px;
}


.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}

</style>
