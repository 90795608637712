<template>
  <div class="px-5 mt-8" id="page-basic0">
    
    <b-form @submit="save_data">
      <b-row>
        <b-col lg="2"></b-col>
        <b-col lg="8">
          <b-alert show variant="light">{{$t('MEMBER.PROFILE_SETTINGS_INFO')}}</b-alert>
          <b-card title="" class="mb-2 mt-8" hide-footer>
            <div>
              
                <template v-for="(item, i) in profilearr">
                  <h5 class="mt-16 mb-4" v-if="item.id === 'membership'">{{ $t('MEMBER.PROFILE_SECTIONS') }}</h5>
                  <h5 class="mt-16 mb-4" v-if="item.id === 'add_membership'">{{ $t('MEMBER.POPUP') }}:</h5>
                  <ProfileRow @change="profile_row_changed" v-if="item.ths_only ? isTHS : true" v-bind:key="i" :profilerow="item" />
                </template>

              
            </div> 
          </b-card>


          <b-card title="" class="mb-2 mt-8" hide-footer>

            <h4>{{ $t('MEMBER.LOGIN') }}</h4>

            <b-row>
              <b-col md="12">
            
                <div class="d-flex align-items-center mb-4">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="settings.enable_member_login_email"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ENABLE_LOGIN_EMAIL') }}</span>
                </div>

              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
            
                <div class="d-flex align-items-center mb-4">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="settings.enable_member_login_bankid"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('MEMBER.ENABLE_LOGIN_BANKID') }}</span>
                </div>

                <p style="font-style: italic; color: #777777;" v-html="$t('MEMBER.PRICE_PER_LOGIN')"></p>

              </b-col>
            </b-row>

            <b-row v-if="!settings.enable_member_login_bankid && !settings.enable_member_login_email">
              <b-col md="12">
                <p style="color: red;">{{ $t('MEMBER.UNABLE_TO_LOGIN_DUE_TO_SETTINGS') }}</p>
              </b-col>
            </b-row>

            <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>

          </b-card>
          
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import ProfileRow from '@/view/pages/ml/settings/profile/ProfileRow.vue';
import {
  SET_PROFILE
} from '@/core/services/store/common.module';
import store from '@/core/services/store';


export default {
  name: 'MemberProfileSettings',
  mixins: [ toasts ],
  components: {
    ProfileRow
  },
  props: ['company'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS'])
  },
  mounted() {
    this.load_data();

    this.settings.enable_member_login_bankid = this.company.company_settings.enable_member_login_bankid;
    this.settings.enable_member_login_email = this.company.company_settings.enable_member_login_email;    
  },
  data() {
    return {
      form: {
        company_id: ''
      },
      profilearr: [],

      settings: {
        enable_member_login_email: false,
        enable_member_login_bankid: false
      }
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_data();
      }
    }
  },
  methods: {
    profile_row_changed(profile_row) {
      const index = this.profilearr.findIndex(item => item.id === profile_row.id);

      if (index <= 0) {
        this.profilearr.push(profile_row);
      }
      else {
        this.profilearr[index] = profile_row;
      }
    },

    async load_data() {
      if (!this.currentCompanyId) {
        return;
      }

      this.profilearr = [];

      var settings = {
        pn: {
          id: 'pn',
          name: this.$t('MEMBER.PERSONNR'),
          checked: false
        },
        na: {
          id: 'na',
          name: this.$t('MEMBER.NAME'),
          checked: false
        },
        em: {
          id: 'em',
          name: this.$t('MEMBER.EMAIL'),
          checked: false
        },
        ph: {
          id: 'ph',
          name: this.$t('MEMBER.PHONE'),
          checked: false
        },
        dob: {
          id: 'dob',
          name: this.$t('MEMBER.DOB'),
          checked: false
        },
        pc: {
          id: 'pc',
          name: this.$t('MEMBER.PUBLIC_ID'),
          checked: false
        },
        st: {
          id: 'st',
          name: this.$t('MEMBER.ADDRESS'),
          checked: false
        },
        co: {
          id: 'co',
          name: this.$t('MEMBER.CO'),
          checked: false
        },
        country: {
          id: 'country',
          name: this.$t('MEMBER.COUNTRY'),
          checked: false
        },
        zi: {
          id: 'zi',
          name: this.$t('MEMBER.ZIPCODE'),
          checked: false
        },
        pa: {
          id: 'pa',
          name: this.$t('MEMBER.POSTAL'),
          checked: false
        },
        rc: {
          id: 'rc',
          name: this.$t('MEMBER.REGION'),
          checked: false
        },
        cc: {
          id: 'cc',
          name: this.$t('MEMBER.COMMUNE'),
          checked: false
        },
        cname: {
          id: 'cname',
          name: this.$t('MEMBER.COMPANY_NAME'),
          checked: false
        },
        student_id: {
          id: 'student_id',
          name: this.$t('MEMBER.STUDENT_ID'),
          checked: false
        },
        public_sex: {
          id: 'public_sex',
          name: this.$t('MEMBER.PUBLIC_SEX'),
          checked: false
        },
        avd: {
          id: 'avd',
          name: this.$t('MEMBER.AVD'),
          checked: false
        },
        org_number: {
          id: 'org_number',
          name: this.$t('MEMBER.ORG_NUMBER'),
          checked: false
        },
        invoice_address: {
          id: 'invoice_address',
          name: this.$t('MEMBER.INVOICE_ADDRESS'),
          checked: false
        },
        invoice_zipcode: {
          id: 'invoice_zipcode',
          name: this.$t('MEMBER.INVOICE_ZIPCODE'),
          checked: false
        },
        invoice_post: {
          id: 'invoice_post',
          name: this.$t('MEMBER.INVOICE_POST'),
          checked: false
        },
        invoice_country: {
          id: 'invoice_country',
          name: this.$t('MEMBER.INVOICE_COUNTRY'),
          checked: false
        },
        membership: {
          id: 'membership',
          name: this.$t('MEMBER.MEMBERSHIP'),
          checked: true
        },
        invoices: {
          id: 'invoices',
          name: this.$t('MEMBER.INVOICES'),
          checked: true
        },
        events: {
          id: 'events',
          name: this.$t('MEMBER.EVENT'),
          checked: true
        },
        orders: {
          id: 'orders',
          name: this.$t('MEMBER.ORDERS'),
          checked: true
        },
        files: {
          id: 'files',
          name: this.$t('MEMBER.FILES'),
          checked: false
        },
        allow_upload: {
          id: 'allow_upload',
          name: this.$t('FILE.ALLOW_UPLOAD'),
          checked: false
        },
        family_members: {
          id: 'family_members',
          name: this.$t('MEMBER.FAMILY_MEMBERS'),
          checked: false
        },
        guardians: {
          id: 'guardians',
          name: this.$t('MEMBER.GUARDIANS'),
          checked: false
        },
        educations: {
          id: 'educations',
          name: this.$t('MEMBER.EDUCATIONS'),
          checked: false
        },
        add_membership: {
          id: 'add_membership',
          name: this.$t('MEMBER.ADD_MEMBERSHIP'),
          checked: false
        },
      };
      
      if (this.company.profile ) {
        for (const id in this.company.profile) {
          if (!(id in settings)) {
            console.error('id not in settings', id);
            continue;
          }

          settings[id].checked = this.company.profile[id] === 1;
        }
      }

      this.profilearr = Object.values(settings);

    },
    async save_data(e) {
      try {
        e.preventDefault();

        var company = { profile: {}, company_id: this.currentCompanyId, ...this.settings };

        for (var i = 0; i < this.profilearr.length; ++i) {
          company.profile[this.profilearr[i].id] = this.profilearr[i].checked ? 1 : 0;
        }

        const res = await axios.put(`/company/${this.currentCompanyId}`, company)

        if (res.status === 200) {
          store.dispatch(SET_PROFILE, company.profile);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));

          return;
        }
        
      }
      catch (err) {
        console.error('save_data error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      
    },
  }
};
</script>
