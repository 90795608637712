<template>
  <div>

    <div class="exp-table">

      <!-- Header row -->
      <b-row v-if="fields && fields.length > 0">
        <b-col v-for="(field, index) in fields" :key="index" :cols="field.cols ? field.cols : 0">
          <div v-if="field.type === 'text' || field.type === 'checkbox' || field.type === 'link' || field.type === undefined" class="exp-header-cell">{{ field.text }}</div>
          <div v-else-if="field.type === 'checkbox'">

            <TableCheckbox
              :row="0"
              :column="index"
              :name="field.name"
              :value="false"
              :text="field.text"
              @value_changed="header_checkbox_value_changed"
            />

          </div>
        </b-col>
        <!-- last col is reserved for actions -->
        <b-col cols="1">

        </b-col>
      </b-row>

      <!-- Iterate over all children as rows -->
      <div v-for="(item, index1) in rows" :key="index1">
        <b-row
          v-if="item._parent_id === null || (item._parent_id !== null && expanded_rows[item._parent_id])"
          :style="content_row_styles(index1, item)"
          :class="content_row_classes(index1, item)" >

          <b-col v-for="(field, index2) in fields" :key="index2" :cols="field.cols ? field.cols : 0">

            <div v-if="index2 === 0" :class="cell_row_classes(index1, index2, item)">

            </div>

            <div v-if="field.type === 'text' || field.type === undefined" class="exp-content-cell">{{ field.__format !== undefined ? field.__format(index1, index2, item[field.name]) : item[field.name] }}</div>
            <div v-else-if="field.type === 'link'" class="exp-content-cell">
              <a href="#" @click.prevent="content_link_clicked(field.name, item, index1, index2)">{{ field.__format !== undefined ? field.__format(index1, index2, item[field.name]) : item[field.name] }}</a>
            </div>
            <div v-else-if="field.type === 'checkbox' && item[field.name] !== undefined" class="exp-content-checkbox">

              <TableCheckbox
                :row="index1"
                :column="index2"
                :name="field.name"
                :value="item[field.name]"
                :item="item"
                @value_changed="content_checkbox_value_changed" />

            </div>


          </b-col>

          <!-- last col is reserved for actions -->
          <b-col cols="1">

            <div class='justify-content-end d-flex'>
              <a v-if="item.children && item.children.length > 0 && !item._expanded" href="#"
                class="mt-2" @click.prevent="expand_row_clicked(index1, item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/angle-up-solid.svg"></inline-svg>
                </span>
              </a>

              <a v-if="item.children && item.children.length > 0 && item._expanded" href="#"
                class="mt-2" @click.prevent="collapse_row_clicked(index1, item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg style="fill: #919191" src="/assets/svg/angle-down-solid.svg"></inline-svg>
                </span>
              </a>

            </div>
          </b-col>
        </b-row>
      </div>


    </div>
  </div>
</template>

<style lang="css" scoped>
.exp-row {
  min-height: 50px;
  border-top: 1px solid #bdbdbd;
}

.exp-header-cell {
  font-weight: bold;
  padding: 8px;
}

.exp-content-cell {
  padding: 8px;
  vertical-align: middle !important;
  padding-top: 11px;
}

.exp-content-checkbox {
  padding-top:11px;
}

.exp-table {}

.highlightable:hover {
  background-color: #f5f5f5;
}

.cell-indent-0 {
  padding-left: 0px !important;
  width: 2px;
  height: 48px;
  float: left;
}

.cell-indent-1 {
  padding-left: 4px !important;
  width: 24px;
  height: 48px;
  float: left;
}

.cell-indent-2 {
  padding-left: 8px !important;
  width: 36px;
  height: 48px;
  float: left;
}

.cell-indent-3 {
  padding-left: 12px !important;
  width: 48px;
  height: 48px;
  float: left;
}

.cell-indent-4 {
  padding-left: 64px !important;
  width: 48px;
  height: 48px;
  float: left;
}

.cell-indent-5 {
  padding-left: 68px !important;
  width: 48px;
  height: 48px;
  float: left;
}

.cell-indent-6 {
  padding-left: 72px !important;
  width: 48px;
  height: 48px;
  float: left;
}
</style>

<script>
import TableCheckbox from './TableCheckbox.vue';


export default {
  name: 'expandable-table',
  data() {
    return {
      local_children: [],
      dependency_recalc: 0,
      checkboxes: {},
      expanded_rows: {},
      test: false
    };
  },
  props: {
    children: {
      type: Array,
      default: []
    },
    fields: {
      type: Array,
      default: []
    },
    highlightable: {
      type: Boolean,
      default: false
    },
    indent_colors: {
      type: Array,
      default() { return ['#FDEEB7', '#FEF9E7', '#FFFDF8', '#FFFFFF'] }
    }
  },
  emits: ['content_link_clicked', 'cell_value_changed'],
  async mounted() {
    if (this.children) {
      this.local_children = [...this.children];
    }
  },
  methods: {

    content_link_clicked(name, item, row_index, col_index) {
      this.$emit('content_link_clicked', name, item, row_index, col_index);
    },

    content_row_styles(row_index, item) {
      const styles = {
        'background-color': this.indent_colors[item._indentation]
      }

      return styles;
    },

    content_row_classes(row_index, item) {
      const classes = {
        'top-line': true,
        'exp-row': true,
        'highlightable': this.highlightable,
      }

      // add row indent class based in item._indentation
      classes[`row-indent-${item._indentation}`] = true;

      return classes;
    },

    cell_row_classes(row_index, col_index, item) {

      if (col_index === 0) {
        /// determine which level of indentation we are at

        return `cell-indent-${item._indentation}`
      }

      return '';
    },

    header_checkbox_value_changed(value) {

      /// need to update all rows with value

      for (let i = 0; i < this.local_children.length; ++i) {
        this.local_children[i][this.fields[value.column].name] = value.value;
      }

    },

    content_checkbox_value_changed(value) {
      this.$emit('cell_value_changed', value);
    },

    expand_row_clicked(row_index, item) {
      item._expanded = true;
      // Vue3 compatability for Vue.set
      item = { ...item };

      this.recursive_expand_children(item);

      this.dependency_recalc++;
    },
    collapse_row_clicked(row_index, item) {
      item._expanded = false;
      // Vue3 compatability for Vue.set
      item = { ...item };

      this.recursive_collapse_children(item);

      this.dependency_recalc++;

    },

    recursive_expand_children(item) {
      item._expanded = true;
      // Vue3 compatability for Vue.set
      item = { ...item }

      this.expanded_rows[item._id] = true;
      // Vue3 compatability for Vue.set
      this.expanded_rows = { ...this.expanded_rows };

      if (item.children) {
        for (const ch of item.children) {
          this.recursive_expand_children(ch);
        }
      }

      this.dependency_recalc++;
    },

    recursive_collapse_children(item) {
      item._expanded = false;
      // Vue3 compatability for Vue.set
      item = { ...item }

      this.expanded_rows[item._id] = false;
      // Vue3 compatability for Vue.set
      this.expanded_rows = { ...this.expanded_rows };

      if (item.children) {
        for (const ch of item.children) {
          this.recursive_collapse_children(ch);
        }
      }

      this.dependency_recalc++;
    },

    recursive_set_children_fields(node, indentation, id_counter, rows) {
      node.children.forEach((child_item, child_index) => {

        rows.push(child_item);

        child_item._indentation = indentation;
        child_item._id = `row-${indentation}-${(++id_counter)}`;
        child_item._parent_id = node._id;

        if (child_item.children && child_item.children.length > 0) {

          if (child_item._expanded === undefined) {
            child_item._expanded = true;
          }

          this.recursive_set_children_fields(child_item, indentation + 1, id_counter, rows);

        }
      });
    }

  },

  watch: {
    children: {
      deep: true,
      handler(val) {
        this.local_children = [...val];
      }
    }
  },

  computed: {

    rows() {

      this.dependency_recalc;

      // we need to take the children property and add all child "children" to this rows array
      const rows = [];

      let id_counter = 0;

      this.local_children.forEach((item, index) => {
        rows.push(item);

        item._indentation = 0;
        item._id = `row-${(++id_counter)}`;
        item._parent_id = null;

        if (item.children) {
          if (item._expanded === undefined) {
            item._expanded = true;
          }

          this.recursive_set_children_fields(item, 1, id_counter, rows);
        }
      });

      for (const r of rows) {
        this.expanded_rows[r._id] = r._expanded;
        // Vue3 compatability for Vue.set
        this.expanded_rows = { ...this.expanded_rows };
      }

      return rows;
    },
  },
  components: { TableCheckbox }
};
</script>
